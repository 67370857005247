<template>
  <v-row>
    <v-col>
      <v-card class="mt-2">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="items"
          :items-per-page="15"
          :search="search"
          :custom-sort="customSort"
          sort-by="date"
          sort-desc
          show-expand
          item-key="index"
        >
          <template v-slot:item.date="{ item }">
            <DateValue :value="item.date" />, {{ item.time }}
          </template>
          <template v-slot:item.level="{ item }">
            <LookupValue :value="item.level" />
          </template>
          <template v-slot:item.job="{ item }">
            {{ item.job.name }}
          </template>
          <template v-slot:item.course="{ item }">
            <Course :value="item.course" />
          </template>
          <template v-slot:item.person="{ item }">
            <PersonItem :value="item.person" />
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
              <v-card tile dark class="py-2 px-4"
                ><code>{{ item.message }}</code></v-card
              >
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col cols="auto">
      <v-card flat>
        <v-card-text class="text-center">
          <DateValue :value="startDate" /> bis <DateValue :value="endDate" />
        </v-card-text>
      </v-card>
      <v-date-picker
        v-model="dates"
        range
        :first-day-of-week="1"
        :max="today()"
        no-title
      ></v-date-picker>
    </v-col>
  </v-row>
</template>

<script>
import Course from "@/components/Course.vue";
import DateValue from "common/components/DateValue.vue";
import LookupValue from "common/components/LookupValue.vue";
import PersonItem from "@/components/PersonItem.vue";

import { today } from "common/utils/date.js";

export default {
  name: "JobsLog",
  components: { Course, DateValue, LookupValue, PersonItem },
  props: ["search"],
  data: () => ({
    loading: false,
    dates: [],

    items: [],
    headers: [
      { text: "Zeit", value: "date" },
      { text: "Level", value: "level" },
      { text: "Job", value: "job" },
      { text: "Kurs", value: "course" },
      { text: "Person", value: "person" },
    ],
  }),
  computed: {
    startDate() {
      return this.dates[0] < this.dates[1] ? this.dates[0] : this.dates[1];
    },
    endDate() {
      return this.dates[0] < this.dates[1] ? this.dates[1] : this.dates[0];
    },
  },
  watch: {
    dates() {
      if (this.dates.length == 2) {
        this.getData();
      }
    },
  },
  methods: {
    today,
    async getData() {
      this.loading = true;
      const data = await this.apiList({
        resource: "job/log",
        query: `startDate=${this.startDate}&endDate=${this.endDate}`,
      });
      this.items = data.map((item, index) => ({ index, ...item }));
      this.loading = false;
    },
    customSort(items, isDesc) {
      items.sort((a, b) => {
        if (a.date + a.time > b.date + b.time) {
          return isDesc ? -1 : 1;
        }
        if (a.date + a.time < b.date + b.time) {
          return isDesc ? 1 : -1;
        }
        return 0;
      });
      return items;
    },
  },
  async created() {
    this.dates = [this.today(), this.today()];
    this.getData();
  },
};
</script>
